@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Work Sans';
}

body {
    margin: 0;
    background-color: #F8F8FF;
}

select option {
    color:#333;
    width: 100px;
    overflow: hidden;
}